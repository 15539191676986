// import { stakingCreate } from "../contracts/staking";
import { toBigNumberFloat, formatStringFloat } from "@/utils/big-number.js";
import { Contract, providers, BigNumber } from "ethers";
import Staking from "../contracts/abi/Staking.abi.json";
import { safeWrite } from "../utils/safe";
import { toInt, toHex } from "../utils/big-number";
import { ERR_MSG } from "./dialogs";

// const TWENTY_GWEI = "20000000000";

const STAKING_ADDRESS = process.env.VUE_APP_STAKING_ADDRESS;

export const state = {
    loadingStakes: false,
    contract: null,
    gas: 0,
    gasPrice: 0,
    stakes: [],
    lastValidatorID: "",
    totalSupply: "",
    validatorsIDs: [],
    withdrawalPeriodTime: 0,
    requests: [],
    rewards: [],
    signer: null,
    isValidator: false
};

export const getters = {
    getTotalSupply(state, _rootState, index) {
        const totalSupply =
            toInt(state.totalSupply) - toInt(index.zeroAccBalance);

        return toHex(totalSupply);
    },

    validatorsIDs(state) {
        return state.validatorsIDs;
    },
    getLoadingStakes(state) {
        return state.loadingStakes;
    },
    getStakes(state) {
        return state.stakes.map(s => ({
            id: s.validatorId,
            stakingName: "created",
            validator: s.validatorId,
            amount: formatStringFloat(s.stakeAmount.toString(), 18, 2),
            rewards: formatStringFloat(s.rewards.toString(), 18, 4),
            action: "unstake",
            claim: "Claim rewards"
        }));
    },
    getwithdrawalPeriod(state) {
        return state.withdrawalPeriodTime;
    },
    getIsValidator(state) {
        return state.isValidator;
    },
    filteredUserStakes(state, getters) {
        return getters.getStakes.filter(s =>
            Boolean(Number(s.amount) || Boolean(Number(s.rewards)))
        );
    },
    filteredRequsest(state) {
        let concatReq = [];
        state.requests.map(r => (concatReq = [...concatReq, ...r]));
        return concatReq;
    }
    // getInputData(rootState) {
    //     return rootState.inputData;
    // }
};

export const mutations = {
    setContract(state, contract) {
        state.contract = contract;
    },
    setLastValidator(state, lastValidatorID) {
        state.lastValidatorID = lastValidatorID;
    },
    setTotalSupply(state, totalSupply) {
        state.totalSupply = totalSupply;
    },
    setValidatorID(state, validatorID) {
        state.validatorsIDs.push(validatorID);
    },
    setEmptyValidatorIDs(state) {
        state.validatorsIDs = [];
    },
    setStake(state, stake) {
        state.stakes.push(stake);
    },
    setStakes(state, stakes) {
        state.stakes = stakes;
    },
    setDialogLoadingStakes(state, loading) {
        state.loadingStakes = loading;
    },
    setWithdrawalPeriod(state, period) {
        state.withdrawalPeriodTime = period;
    },
    setIsValidator(state, validator) {
        state.isValidator = validator;
    },
    setEmptyRequest(state) {
        state.requests = [];
    },
    setRequest(state, request) {
        state.requests.push(request);
    },
    setSigner(state, signer) {
        state.signer = signer;
    }
};

export const actions = {
    async createContract({ rootState, commit, dispatch }) {
        let provider = null;
        let signer = null;
        if (rootState.walletType === "metamask") {
            provider = new providers.Web3Provider(window.ethereum);
            signer = provider.getSigner(rootState.address);
        }
        if (rootState.walletType === "trustwallet") {
            provider = new providers.Web3Provider(window.ethereum);
            signer = provider.getSigner(rootState.address);
        }
        if (rootState.walletType === "wc") {
            console.log(rootState.walletconnect.wcProvider);
            // provider = new providers.Web3Provider(
            //     rootState.walletconnect.wcProvider
            // );
            provider = new providers.JsonRpcProvider(
                rootState.network.networkOptions.rpcUrls[0]
            );
            signer = provider.getSigner(rootState.address);
        }
        console.log(provider, signer);

        const contract = new Contract(STAKING_ADDRESS, Staking, signer);
        console.log(contract, "contract");

        commit("setContract", contract);

        const lastValidator = await contract.lastValidatorID();
        // const totalSupply = await contract.totalSupply();
        // console.log(totalSupply.toNumber(), "init contract")
        commit("setLastValidator", lastValidator.toNumber());
        // commit("setTotalSupply", totalSupply.toNumber());
        dispatch("getValidatorsIDs");
        dispatch("withdrawalPeriodTime");
    },
    async getValidatorsIDs({ state, rootState, commit, dispatch }) {
        commit("setDialogLoadingStakes", true);
        if (state.contract) {
            const getValidator = async id =>
                await state.contract.getValidator(id);

            const getStakeValue = async id =>
                await state.contract.getStake(rootState.address, id);
            const getRewards = async id =>
                await state.contract.pendingRewards(rootState.address, id);
            commit("setEmptyValidatorIDs");
            commit("setStakes", []);
            if (state.lastValidatorID && !state.validatorsIDs.length) {
                for (let i = 1; i <= Number(state.lastValidatorID); i++) {
                    const validator = await getValidator(i);

                    if (
                        Number(validator.status) === 0 &&
                        Number(validator.createdTime)
                    ) {
                        commit("setValidatorID", i);

                        // set stakes
                        if (rootState.address) {
                            const rewardValue = await getRewards(i);
                            const stakeValue = await getStakeValue(i);
                            commit("setStake", {
                                validatorId: i,
                                stakeAmount: stakeValue,
                                rewards: rewardValue
                            });
                        }
                    }
                }
            }
        }
        commit("setDialogLoadingStakes", false);
        commit("setEmptyRequest");
        dispatch("getWrRequests");
    },
    async getWrRequests({ state, rootState, commit }) {
        state.validatorsIDs.forEach(async v => {
            let isFound = false;
            let offset = 0;
            let limit = 10;
            let wrReq = [];
            while (!isFound) {
                const req = await state.contract.getWrRequests(
                    rootState.address,
                    v,
                    offset,
                    limit
                );
                if (!req) {
                    isFound = true;
                    return;
                }

                if (req.length) {
                    req.map((r, idx) => {
                        if (Number(r.time)) {
                            const item = {
                                dateUnstaking: r.time,
                                epoch: r.epoch,
                                amount: r.amount
                            };
                            wrReq.push({
                                ...item,
                                wrId: idx + offset,
                                validator: v
                            });
                        }
                    });
                }

                if (Number(req[req.length - 1].time) === 0) {
                    isFound = true;
                } else if (Number(req[req.length - 1].time) > 0) {
                    offset = offset + limit;
                } else isFound = true;
            }
            offset = 0;
            commit("setRequest", wrReq);
        });
    },
    async withdrawalPeriodTime({ state, rootState, commit }) {
        if (state.contract) {
            const id = await state.contract.getValidatorID(rootState.address);
            const genesisValidator = await state.contract.genesisValidator();
            console.log(id.toNumber(), genesisValidator, "AAAAA");
            if (id.toNumber() !== 0 || genesisValidator == rootState.address) {
                commit("setIsValidator", true);
                const time = await state.contract.withdrawalPeriodTimeValidator();
                commit("setWithdrawalPeriod", time.toNumber());
            } else {
                const time = await state.contract.withdrawalPeriodTime();
                commit("setWithdrawalPeriod", time.toNumber());
            }
        }
    },

    // write functions
    async delegate({ state, dispatch }, payload) {
        dispatch("setDialogLoading", true);
        dispatch("setEmptyMsg");

        const delegate = toBigNumberFloat(payload.delegate);

        try {
            // ethers
            const [tx] = await safeWrite(
                state.contract.delegate(payload.toValidatorID, {
                    value: BigNumber.from(delegate)
                })
            );

            // web3

            // const tx = await state.contract.methods
            //         .delegate(payload.toValidatorID)
            //         .send({
            //             from: rootState.address,
            //             gasPrice: TWENTY_GWEI,
            //             value: BigNumber.from(delegate)
            //         });

            console.log("tx", tx);
            dispatch("setDialogLoading", false);
            dispatch("setSuccessMsg", `${tx.hash}`);
            dispatch("getValidatorsIDs");
            return tx;
        } catch (err) {
            console.log(err);
            dispatch("setErrorMsg");
            dispatch("setDialogLoading", false);
            return ERR_MSG;
        }
    },
    async undelegate({ state, dispatch }, payload) {
        dispatch("setDialogLoading", true);

        dispatch("setEmptyMsg");
        if (state.contract) {
            console.log(payload, "payload", toBigNumberFloat(payload.amount));

            const amount = toBigNumberFloat(payload.amount);

            try {
                console.log(payload.toValidatorID, amount, "undelegate params");
                // ethers
                const [tx] = await safeWrite(
                    state.contract.undelegate(payload.toValidatorID, amount)
                );

                // web3
                // const tx = await state.contract.methods
                //     .undelegate(payload.toValidatorID, amount)
                //     .send({
                //         from: rootState.address,
                //         gasPrice: TWENTY_GWEI
                //     });

                console.log("undelegate", tx);
                dispatch("setSuccessMsg", `${tx.hash}`);
                dispatch("setDialogLoading", false);
                dispatch("getValidatorsIDs");
            } catch (err) {
                console.log(err);
                dispatch("setErrorMsg");
                dispatch("setDialogLoading", false);
                return ERR_MSG;
            }
        }
    },
    async claimRewards({ state, dispatch }, payload) {
        dispatch("setDialogLoading", true);
        dispatch("setEmptyMsg");

        console.log("state.contract", state.contract);

        if (state.contract) {
            try {
                console.log(payload.toValidatorID, "claimRewards params");

                const [tx] = await safeWrite(
                    state.contract.claimRewards(payload.toValidatorID)
                );

                dispatch("setSuccessMsg", `${tx.hash}`);
                dispatch("setDialogLoading", false);
                dispatch("getValidatorsIDs");
            } catch (err) {
                console.log(err);
                dispatch("setErrorMsg");
                dispatch("setDialogLoading", false);
                return ERR_MSG;
            }
        }
    },
    async withdraw({ state, dispatch }, payload) {
        dispatch("setDialogLoading", true);

        dispatch("setEmptyMsg");
        if (state.contract) {
            try {
                console.log(
                    payload.toValidatorID,
                    payload.wrId,
                    "withdraw params"
                );
                // ethers
                const [tx] = await safeWrite(
                    state.contract.withdraw(payload.toValidatorID, payload.wrId)
                );

                // web3
                // const tx = await state.contract.methods
                //     .withdraw(payload.toValidatorID, payload.wrId)
                //     .send({
                //         from: rootState.address,
                //         gasPrice: TWENTY_GWEI
                //     });

                console.log("withdraw", tx);
                dispatch("setSuccessMsg", `${tx.hash}`);
                dispatch("setDialogLoading", false);
                dispatch("getValidatorsIDs");
            } catch (err) {
                console.log(err);
                dispatch("setErrorMsg");
                dispatch("setDialogLoading", false);
                return ERR_MSG;
            }
        }
    }
};

export const staking = {
    state,
    getters,
    mutations,
    actions
};
