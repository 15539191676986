import { toBigNumberFloat, formatStringFloat } from "@/utils/big-number.js";
import { Contract, providers, BigNumber } from "ethers";
import QuotaStaking from "../contracts/abi/QuotaStaking.abi.json";
import { safeWrite } from "../utils/safe";

const errMsg = "Warning! An error has occurred. Please try again.";
const QUOTA_STAKING_ADDRESS = process.env.VUE_APP_QUOTA_STAKING_ADDRESS;

export const state = {
    contract: null,
    addressTotalStake: 0,
    holdTime: 0,
    withdrawalLoading: false,
    withdrawalRequestIDs: [],
    withdrawalRequests: []
};

export const getters = {
    getUserTotalStake(state) {
        return state.addressTotalStake;
    },
    getWithdrawalLoading(state) {
        return state.withdrawalLoading;
    },
    getWithdrawalRequests(state) {
        return state.withdrawalRequests;
    },
    getHoldTime(state) {
        return state.holdTime;
    }
};

export const mutations = {
    setQuotaContract(state, contract) {
        state.contract = contract;
    },
    setQuotaAddressTotalStakeTotalStake(state, addressTotalStake) {
        state.addressTotalStake = addressTotalStake;
    },
    setQuotaHoldTime(state, holdTime) {
        state.holdTime = holdTime;
    },
    setQuotaStake(state, stake) {
        state.stakes.push(stake);
    },
    setQuotaStakes(state, stakes) {
        state.stakes = stakes;
    },
    setWithdrawalLoading(state, loading) {
        state.withdrawalLoading = loading;
    },
    setActiveWithdrawalRequestIDs(state, withdrawalRequestIDs) {
        state.withdrawalRequestIDs = withdrawalRequestIDs;
    },
    setActiveWithdrawalRequests(state, withdrawalRequest) {
        state.withdrawalRequestIDs = state.withdrawalRequests.push(
            withdrawalRequest
        );
    },
    setEmptyWithdrawalRequests(state) {
        state.withdrawalRequests = [];
    }
};

export const actions = {
    async createQuotaContract({ rootState, commit, dispatch }) {
        let provider = null;
        let signer = null;
        if (rootState.walletType === "metamask") {
            provider = new providers.Web3Provider(window.ethereum);
            signer = provider.getSigner(rootState.address);
        }
        if (rootState.walletType === "trustwallet") {
            provider = new providers.Web3Provider(window.ethereum);
            signer = provider.getSigner(rootState.address);
        }
        if (rootState.walletType === "wc") {
            console.log(rootState.walletconnect.wcProvider);

            provider = new providers.JsonRpcProvider(
                rootState.network.networkOptions.rpcUrls[0]
            );
            signer = provider.getSigner(rootState.address);
        }
        console.log(provider, signer);

        const contract = new Contract(
            QUOTA_STAKING_ADDRESS,
            QuotaStaking,
            signer
        );
        console.log(contract, "contract");

        commit("setQuotaContract", contract);
        dispatch("getHoldTime");
        dispatch("getAddressTotalStake");
        dispatch("getActiveWithdrawalRequestIDs");
    },
    async getHoldTime({ state, commit }) {
        const holdTime = await state.contract.holdTime();
        console.log(holdTime, "holdTime");
        commit("setQuotaHoldTime", holdTime.toNumber());
    },
    async getAddressTotalStake({ rootState, state, commit }) {
        const totalStake = await state.contract.addressTotalStake(
            rootState.address
        );
        commit(
            "setQuotaAddressTotalStakeTotalStake",
            formatStringFloat(totalStake)
        );
    },
    async getActiveWithdrawalRequestIDs({ rootState, state, commit }) {
        if (!state.contract) return null;
        commit("setWithdrawalLoading", true);
        const getActiveWithdrawalRequestIDs = await state.contract.getActiveWithdrawalRequestIDs(
            rootState.address
        );

        commit("setActiveWithdrawalRequestIDs", getActiveWithdrawalRequestIDs);

        const idsWR = await state.contract.getWrRequests(
            rootState.address,
            0,
            0
        );
        commit("setEmptyWithdrawalRequests");
        idsWR.forEach((request, index) => {
            const id = request.id.toNumber();
            const epoch = request.epoch.toString();
            const time = request.time.toNumber();
            const amount = request.amount * 1;

            if (epoch !== "0" && time !== 0 && amount !== "0.0") {
                const formattedTime = new Date(time * 1000).toLocaleString();
                console.log(
                    `WithdrawalRequest #${index}: ID: ${id}, Epoch: ${epoch}, Time: ${formattedTime}, Amount: ${formatStringFloat(
                        amount
                    )} VC`
                );
                commit("setActiveWithdrawalRequests", {
                    index,
                    id,
                    epoch,
                    timeUnstaking: time + state.holdTime * 2,
                    amount: formatStringFloat(amount)
                });
            }
        });
        commit("setWithdrawalLoading", false);
    },

    // write functions
    async quotaStake({ state, dispatch }, payload) {
        dispatch("setDialogLoading", true);
        dispatch("setEmptyMsg");

        const amount = toBigNumberFloat(payload.stake);

        try {
            const [tx] = await safeWrite(
                state.contract.stake({
                    value: BigNumber.from(amount)
                })
            );

            console.log("tx", tx);
            dispatch("setSuccessMsg", `${tx.hash}`);
            dispatch("setDialogLoading", false);
            dispatch("getAddressTotalStake");

            return tx;
        } catch (err) {
            console.log(err);
            dispatch("setErrorMsg");
            dispatch("setDialogLoading", false);
            dispatch("getAddressTotalStake");
            return errMsg;
        }
    },
    async quotaUnstake({ state, dispatch }, payload) {
        dispatch("setDialogLoading", true);
        dispatch("setEmptyMsg");
        if (state.contract) {
            console.log(payload, "payload", toBigNumberFloat(payload.amount));

            const amount = toBigNumberFloat(payload.amount);

            try {
                console.log(amount, "quotaUnstake params");

                const [tx] = await safeWrite(state.contract.unstake(amount));

                console.log("quotaUnstake", tx);
                dispatch("setSuccessMsg", `${tx.hash}`);
                dispatch("setDialogLoading", false);
                dispatch("getAddressTotalStake");
                dispatch("getActiveWithdrawalRequestIDs");
            } catch (err) {
                console.log(err);
                dispatch("setErrorMsg");
                dispatch("setDialogLoading", false);
                dispatch("getAddressTotalStake");
                return errMsg;
            }
        }
    },
    async quotaWithdrawStake({ state, dispatch }, payload) {
        dispatch("setDialogLoading", true);
        dispatch("setEmptyMsg");
        if (state.contract) {
            try {
                console.log(payload.wrID, "withdraw params");
                // ethers
                const [tx] = await safeWrite(
                    state.contract.withdrawStake(payload.wrID)
                );

                console.log("withdraw", tx);
                dispatch("setSuccessMsg", `${tx.hash}`);
                dispatch("setDialogLoading", false);
                dispatch("getActiveWithdrawalRequestIDs");
            } catch (err) {
                console.log(err);
                dispatch("setErrorMsg");
                dispatch("setDialogLoading", false);
                return errMsg;
            }
        }
    }
};

export const quotaStaking = {
    state,
    getters,
    mutations,
    actions
};
