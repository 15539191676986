var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"epochlist"},[(!_vm.queryError)?[_c('f-data-table',{staticClass:"f-data-table-body-bg-color",attrs:{"columns":_vm.dColumns,"items":_vm.dItems,"disable-infinite-scroll":!_vm.dHasNext,"loading":_vm.cLoading,"mobile-view":_vm.cMobileView,"infinite-scroll":"","fixed-header":""},on:{"fetch-more":_vm.fetchMore},scopedSlots:_vm._u([{key:"column-id",fn:function(ref){
var value = ref.value;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-4 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col-8"},[_c('router-link',{attrs:{"to":{
                                name: 'epoch-detail',
                                params: { id: value }
                            }}},[_vm._v(" "+_vm._s(value)+" ")])],1)]):[_c('router-link',{attrs:{"to":{
                            name: 'epoch-detail',
                            params: { id: value }
                        }}},[_vm._v(" "+_vm._s(value)+" ")])]]}},{key:"column-fee",fn:function(ref){
                        var value = ref.value;
                        var item = ref.item;
                        var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-4 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col-8 break-word"},[_c('f-token-value',{attrs:{"value":value,"decimals":6,"use-placeholder":false,"no-currency":""}})],1)]):[_c('f-token-value',{attrs:{"value":value,"decimals":6,"use-placeholder":false,"no-currency":""}})]]}}],null,false,853833703)})]:[_c('div',{staticClass:"query-error"},[_vm._v(_vm._s(_vm.queryError))])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }