var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.darkMode ? 'hometransactionlist-dark' : 'hometransactionlist'},[_c('transition',{attrs:{"enter-active-class":"fadelong-enter-active"}},[_c('f-data-table',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"f-data-table-body-bg-color",attrs:{"columns":_vm.dColumns,"hidden-columns":_vm.hiddenColumns,"items":_vm.dItems,"loading":_vm.cLoading,"f-card-off":"","mobile-view":"","height":"300px","first-m-v-column-width":"5","fixed-header":""},scopedSlots:_vm._u([{key:"column-hash",fn:function(ref){
var value = ref.value;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":{
                                name: 'transaction-detail',
                                params: { id: value }
                            },"title":value}},[_vm._v(_vm._s(_vm._f("formatHash")(value)))])],1)]):[_c('router-link',{attrs:{"to":{
                            name: 'transaction-detail',
                            params: { id: value }
                        },"title":value}},[_vm._v(_vm._s(_vm._f("formatHash")(value)))])]]}},{key:"column-timestamp",fn:function(ref){
                        var value = ref.value;
                        var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col"},[_c('timeago',{attrs:{"datetime":_vm.timestampToDate(value),"auto-update":1,"converter-options":{ includeSeconds: true }}})],1)]):[_c('timeago',{attrs:{"datetime":_vm.timestampToDate(value),"auto-update":1,"converter-options":{ includeSeconds: true }}})]]}},{key:"column-address",fn:function(ref){
                        var value = ref.value;
                        var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":{
                                name: 'address-detail',
                                params: { id: value }
                            },"title":value}},[_vm._v(_vm._s(_vm._f("formatHash")(value)))])],1)]):[_c('router-link',{attrs:{"to":{
                            name: 'address-detail',
                            params: { id: value }
                        },"title":value}},[_vm._v(_vm._s(_vm._f("formatHash")(value)))])]]}},{key:"column-from",fn:function(ref){
                        var value = ref.value;
                        var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":{
                                name: 'address-detail',
                                params: { id: value }
                            },"title":value}},[_vm._v(_vm._s(_vm._f("formatHash")(value)))])],1)]):[_c('router-link',{attrs:{"to":{
                            name: 'address-detail',
                            params: { id: value }
                        },"title":value}},[_vm._v(_vm._s(_vm._f("formatHash")(value)))])]]}},{key:"column-to",fn:function(ref){
                        var value = ref.value;
                        var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":{
                                name: 'address-detail',
                                params: { id: value }
                            },"title":value}},[_vm._v(_vm._s(_vm._f("formatHash")(value)))])],1)]):[_c('router-link',{attrs:{"to":{
                            name: 'address-detail',
                            params: { id: value }
                        },"title":value}},[_vm._v(_vm._s(_vm._f("formatHash")(value)))])]]}},{key:"column-amount",fn:function(ref){
                        var value = ref.value;
                        var item = ref.item;
                        var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col"},[(_vm.addressCol)?[_c('f-account-transaction-amount',{attrs:{"address":_vm.addressCol,"from":_vm.getFrom(item),"to":_vm.getTo(item),"amount":value}})]:[_vm._v(" "+_vm._s(value)+" ")]],2)]):[(_vm.addressCol)?[_c('f-account-transaction-amount',{attrs:{"address":_vm.addressCol,"from":_vm.getFrom(item),"to":_vm.getTo(item),"amount":value}})]:[_vm._v(" "+_vm._s(value)+" ")]]]}}])},'f-data-table',Object.assign({}, _vm.$attrs, _vm.$props),false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }